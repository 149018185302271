import classes from "./Contact.module.css";
import React, { useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const onChangeHandler = (event) => {
    switch (event.target.id) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      default:
        console.log("default case");
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setError("");
    const formName = name;
    const formEmail = email;
    const formMessage = message;
    Axios.post("https://contact.hager-web.com/maxcontact", {
      name: formName,
      email: formEmail,
      message: formMessage,
    })
      .then((result) => {
        setName("");
        setEmail("");
        setMessage("Ihre Nachricht wurde gesendet!");
      })
      .catch((err) => setError(err.response.data.errorMessage));
  };

  let errorContent = null;

  if (error !== "") {
    errorContent = <p className={classes.Error}>{error}</p>;
  }

  return (
    <div id="contact" className={classes.Contact}>
      <h2>Kontakt</h2>
      <div>
        <p>
          <span>AVM - Meisterbetrieb</span>
          <br />
          Inh.: Maxim Ringelmann <br />
          Haßlinghauser Straße 139A
          <br />
          42279 Wuppertal
          <br />
        </p>
        <div className={classes.Phone}>
          <div>
            <FontAwesomeIcon icon={faMobileAlt} />
            <a href="tel:+4917682014579" title="Mobil">
              +49 (0)176 82014579
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <a href="tel:+4920229541651" title="Telefon">
              +49 (0)202 29541651
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:info@avm-shk.de" title="Mail">
              info@avm-shk.de
            </a>
          </div>
        </div>
      </div>

      {errorContent}
      <form onSubmit={onSubmitHandler}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            placeholder="Bitte geben Sie Ihren Namen ein."
            onChange={onChangeHandler}
          ></input>
        </label>
        <label>
          Email:
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            placeholder="Bitte geben Sie Ihre Emailadresse ein."
            onChange={onChangeHandler}
          ></input>
        </label>
        <label>
          Ihre Nachricht an uns:
          <textarea
            name="message"
            id="message"
            value={message}
            rows="5"
            onChange={onChangeHandler}
            placeholder="Was Sie uns mitteilen wollen....(..mit Telefonnummer, wir rufen zurück)"
          />
        </label>

        <button type="submit">Absenden</button>
        <p>
          Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in
          unserer <a href="/privacy">Datenschutzerklärung</a>.
        </p>
      </form>
    </div>
  );
};

export default Contact;

import classes from "./Footer.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  library.add(fab);
  return (
    <footer className={classes.Footer}>
      <div className={classes.Impressum}>
        <p>AVM - Meisterbetrieb</p>
        <p>Inh.: Maxim Ringelmann</p>
        <p>Haßlinghauser Straße 139A</p>
        <p>42279 Wuppertal</p>
      </div>
      <div className={classes.Links}>
        <h3>Informationen:</h3>
        <Link to="/impressum">Impressum</Link>
        <Link to="/privacy">Datenschutz</Link>
      </div>
      <div className={classes.Copyright}>
        <a href="https://hager-web.com">Website by hager-web.com</a>
      </div>
      <div className={classes.Social}>
        <h3>Social Media:</h3>
        <a
          href="https://www.facebook.com/AVM-Meisterbetrieb-119242339987674/"
          name="facebook-icon"
          className={classes.Icon}
        >
          <FontAwesomeIcon
            icon={["fab", "facebook-square"]}
            size="2x"
            color="#3b5998"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
